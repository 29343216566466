
















import { Component, Prop, Vue } from 'vue-property-decorator'
import MultiLanguage from '@/shared/components/MultiLanguage.vue'

@Component({
  components: { MultiLanguage }
})
export default class AuthLayout extends Vue {
  @Prop() headline!: string
}
