











import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Field from '@/shared/classes/form/field'
import AuthLayout from '@/admin/layouts/AuthLayout.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { Positions } from '@/shared/interfaces/classes/form.interfaces'
import { FieldTypes } from '@/shared/components/form/field-types'
import { AdminRoutes } from '@/shared/router/admin'

@Component({
  components: { Form, AuthLayout },
  methods: { __ }
})
export default class SetPassword extends Vue {
  message: string | null = null
  form: FormBase | null = null

  created() {
    const { token, email } = this.$router.currentRoute.params

    this.form = new FormBase()
      .setEndpoint('password/reset')
      .setSubmit({
        text: __('admin.views.set-password.form.submit'),
        position: Positions.center,
      })
      .setFields([
        new Field()
          .setType(FieldTypes.password)
          .setKey('password')
          .setTitle(__('admin.views.set-password.form.password')),
        new Field()
          .setType(FieldTypes.password)
          .setKey('password_confirmation')
          .setTitle(__('admin.views.set-password.form.password-confirmation')),
      ])
      .setInjectValues({ token, email })
      .setOnSuccess(this.onSuccess)
  }

  onSuccess(response: any) {
    this.message = response.message
  }

  goToLogin(): void {
    this.$router.push({ name: AdminRoutes.login })
  }
}
